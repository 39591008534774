import React, { useContext, useEffect, useState } from "react"
import MdWrapper from "@/components/MarkdownWrap"
import styled from "styled-components"
import Axios from "axios"

const TabWrapper = styled.div`
  max-width: 1000px;
  margin: 0px auto;
  padding: 0 20px;
  display: flex;
  //height: 40px;
  flex-wrap: wrap;
`

const hlColor = `#0061FF`

const Tab = styled.div`
  display: block;
  position: relative;
  padding: 0 15px;
  color: ${props => (props.hl ? `white` : `#eeeeee`)};
  font-size: 16px;
  //height: 33px;
  margin: 15px 0px 0;
  line-height: 33px;
  height: 40px;
  //border-bottom: 1px solid ${props => (props.hl ? hlColor : "#D0D3D7")};
  color: ${props => (props.hl ? hlColor : "#D0D3D7")};
  //font-weight: ${props => (props.hl ? "bold" : 300)};
  font-weight: bold;
  transition: all 0.2s;
  flex: auto;
  cursor: pointer;
  text-align: center;
  &:hover {
    color: ${hlColor};
  }

  &:after {
  transition: all 0.2s;
  content: "";
  position: absolute;
  bottom:  ${props => (props.hl ? `0px` : "1px")};
  left: 0;
  right:0;
  height: ${props => (props.hl ? `3px` : "1px")};
  background-color:${props => (props.hl ? hlColor : "#D0D3D7")};
  pointer-events: none;
  }
  -webkit-tap-highlight-color: transparent;
  @media screen and (max-width: 800px) {
  &{
  flex:none;
  width: 100%;
  }
}
`

const Title = styled.h1`
  max-width: 1000px;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  margin: 0 auto 30px;
  padding: 0 45px;
  overflow: hidden;
`

function Index() {
  let selectedLang = "de"

  const [currentCate, setCate] = useState(0)
  const [tabs, setTabs] = useState(null)
  const [markdown, setMarkdown] = useState(null)

  useEffect(() => {
    getTab()
  }, [])

  // 获取tab内容
  const getTab = () => {
    Axios.get(`${process.env.resourceUrl}docs/nebula_h5_docs/FAQ/tab.json`).then(function(data) {
      setTabs(data.data)
    })
  }

  const getMd = (filename, lang) => {
    Axios.get(`${process.env.resourceUrl}docs/nebula_h5_docs/FAQ/${lang}/${filename}.md`).then(function(data) {
      setMarkdown(data.data)
    })
  }

  useEffect(() => {
    tabs != null && getMd(tabs[selectedLang][currentCate].name, selectedLang)
  }, [currentCate, tabs])

  return (
    <>
      <Title style={{ paddingTop: 80 }}>FAQ</Title>
      {tabs != null ? (
        <TabWrapper>
          {tabs[selectedLang].map(item => (
            <Tab
              key={item.key}
              onClick={() => setCate(item.key)}
              hl={item.key == currentCate}
            >
              <span>{item.value}</span>
            </Tab>
          ))}
        </TabWrapper>
      ) : (
        ""
      )}
      {markdown != null ? (
        <MdWrapper md={markdown} blankLayout />
      ) : (
        ""
      )}
    </>
  )
}

export default Index
